import React from 'react';
import { withPlaceholder } from '@sitecore-jss/sitecore-jss-react';
import HomeCardsContainer from '../../SharedComponents/HomeCardsContainer';
import RenderingStyling from '../../../lib/RenderingStyling';

const DownloadsContainer = (props) => (
  <HomeCardsContainer {...RenderingStyling(props.rendering.componentName)} {...props} Cards={props.DownloadCardsPlaceholder} />
);

export default withPlaceholder('DownloadCardsPlaceholder')(DownloadsContainer);
