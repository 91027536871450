import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { AdsCtaLink } from '@arm/arm-design-system-react';
import RenderingStyling from '../../../lib/RenderingStyling';
import HomeCardsContainer from '@arm/ce-shared-components/components/CoveoHeadless/HomeComponents/HomeCardsContainer'
import config from '../../../temp/config';
import '../../SharedComponents/HomeCardsContainer/HomeCardsContainer.css';
import { UseGlobalState } from '../../../context';

const RecommendedContainer = (props) => {
  const [{ user }] = UseGlobalState();
  return (
    <section className="u-padding-top-1-1/2 u-padding-bottom-1-1/2 md:u-padding-top-2 md:u-padding-bottom-2 u-bg-color-arm-light-grey-alt dark:u-bg-color-base">
        <div className="c-container"  {...RenderingStyling(props.rendering.componentName)} >
            <div className="c-row">
                <div className="c-col">
                {props.fields.link!=null && props.fields.link.value.href!=""?
                    <AdsCtaLink link={props.fields.link.value.href} srLabel={props.fields.heading.value} className="dev-c-home-cta-link u-margin-bottom-1 md:u-margin-bottom-1-1/2">
                        <h2 className="u-color-inherit u-margin-0"><Text field={props.fields.heading}></Text></h2>
                    </AdsCtaLink>
                    :
                    <h2 className="u-color-inherit u-margin-bottom-1 md:u-margin-bottom-1-1/2 u-font-size-100% sm:u-font-size-200%"><Text field={props.fields.heading}></Text></h2>
                }
                </div>
            </div>
            <div>
                <HomeCardsContainer
                    CoveoOrganizationID={`${config.REACT_APP_COVEOORGANIZATIONID}`}
                    CoveoGetTokenURI={`${config.REACT_APP_COVEOGETSEARCHTOKENURL}`}
                    CoveoSearchHub={`${config.REACT_APP_COVEOHOMEPAGERECOMMENDEDSEARCHHUB}`}
                    CoveoQueryPipeline={`${config.REACT_APP_COVEOHOMEPAGERECOMMENDEDPIPELINE}`}
                    user={{ token: user.user?.token, isLoggedIn: user.isAuth }}
                />
            </div>
        </div>
    </section>
  )
}

export default RecommendedContainer;
