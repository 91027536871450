import { 
    AZUREB2CSERVICE_INIT
  } from "../../constants/actionTypes";
  
  
  export const initialUserState = { user: null, isAuth: false, isLoginDone: false};
  
  export function user (state, action) {
    switch (action.type) {
      case AZUREB2CSERVICE_INIT:
        return {
          ...state,
          user: action.data.user,
          isAuth: action.data.isAuth,
          isLoginDone: true
        };
      default:
        return state;
    }
  }
