import React from 'react';

const LinkWithIconListItem = ({ fields }) => {
    var iconCssClass = fields.icon?.fields.className?.value.length > 0 ? fields.icon?.fields.className?.value : "fa-file-alt";
    return (
            fields.link?.value?.href !== '' &&
                fields.link.value.linktype === 'media' ?
                <li>
                    <a data-track="true" data-track-value={fields.link?.value?.text} href={fields.link?.value?.href.replace('/sitecore/media-library','/-/media').replace('/sitecore/media library','/-/media') + '.ashx'} alt={fields.link?.value?.text}><span className={"u-margin-right-1/2 fal " + iconCssClass}></span>{fields.link?.value?.text}</a>
                </li>:
                <li>
                    <a data-track="true" data-track-value={fields.link?.value?.text} href={fields.link?.value?.href} alt={fields.link?.value?.text}><span className={"u-margin-right-1/2 fal " + iconCssClass}></span>{fields.link?.value?.text}</a>
                </li>
    );
};

export default LinkWithIconListItem;
