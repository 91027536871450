import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { AdsCtaLink } from '@arm/arm-design-system-react';
import './HomeCardsContainer.css'
import RenderingStyling from '../../../lib/RenderingStyling';

const HomeCardsContainer = (props) => (
  <div className="c-container"  {...RenderingStyling(props.rendering.componentName)} >
    <div className="c-row">
      <div className="c-col">
      {props.fields.link!=null && props.fields.link.value.href!=""?
        <AdsCtaLink link={props.fields.link.value.href} srLabel={props.fields.heading.value} className="dev-c-home-cta-link u-margin-bottom-1 md:u-margin-bottom-1-1/2">
          <h2 className="u-color-inherit u-margin-0"><Text field={props.fields.heading}></Text></h2>
        </AdsCtaLink>
        :
        <h2 className="u-color-inherit u-margin-bottom-1 md:u-margin-bottom-1-1/2  u-font-size-100% sm:u-font-size-200%"><Text field={props.fields.heading}></Text></h2>
      }
      </div>
    </div>
    <div className="c-row">
      <div className="c-col">
        <div className="u-display-grid u-gap-1 md:u-grid-columns-3">  
          {props.Cards}
        </div>
      </div>
    </div>
  </div>
)

export default HomeCardsContainer;
