import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import StarsRating from '../../SharedComponents/StarsRating';
import { withPlaceholder } from '@sitecore-jss/sitecore-jss-react';
import RenderingStyling from '../../../lib/RenderingStyling';
import './SoftwareDownloadsContainer.css';

const SoftwareDownloadsContainer = ({ fields, rendering, softwaredownloadcontent }) => (
    <>
        <div {...RenderingStyling(rendering.componentName)} className="c-row c-download-container c-row c-download-container u-padding-top-1-1/2 u-padding-bottom-1-1/2">
            
            <div className="c-col c-col-12 c-download-components u-margin-right-1 u-margin-left-1">
                <div className="c-row dev-c-tab-heading dev-c-tab-filter-heading 
                c-horizontal-rule u-display-grid u-gap-1 lg:u-gap-0 lg:u-flex u-align-items-center 
                lg:u-align-items-start u-position-relative lg:u-justify-content-between">
                    <h2 className="u-margin-top-0"><Text field={fields.heading}></Text></h2>
                    <StarsRating uid={ rendering.uid } />
                </div>
            </div>
            <div className="c-col c-col-12 c-download-components">
                <div className="c-software-container">
                    {softwaredownloadcontent}
                </div>
            </div>
        </div>
        

    </>
);

export default withPlaceholder(['softwaredownloadcontent'])(SoftwareDownloadsContainer);

