import {
    AZUREB2CSERVICE_INIT
  } from '../../constants/actionTypes';
  import {
    azureInit
  } from '../../services/userService';
  
  export const initAzureB2CAction = (dispatch)=> {
    azureInit().then((data) => {
      dispatch({
        type: AZUREB2CSERVICE_INIT,
        data: data || {}
      });
    });
  }