import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import config from '../../../temp/config';
import './StarRatingMobile.css';
import loadable from '@loadable/component';
import RenderingStyling from '../../../lib/RenderingStyling';
const FeedbackForm = loadable(
    () => import('@arm/ce-shared-components/components/FeedbackForm/FeedbackForm'),
    { ssr: false }
);


const StarsRatingMobile = ({ uid, sitecoreContext }) => {
    return (
        <div className="xl:u-hide u-grid-auto-flow-column u-align-items-center u-justify-content-center u-gap-1 u-margin-bottom-2 dev-c-feedbackform" {...RenderingStyling('StarRatingMobile')} >
            <FeedbackForm
                id={uid}
                title={sitecoreContext.route.fields.pageTitle?.value}
                forms={[
                    { id: config.REACT_APP_MEDALLIARATINGFORMID, type: "rating" },
                    { id: config.REACT_APP_MEDALLIAFEEDBACKFORMID, type: "feedback" }
                ]} />
        </div>
    );
};

export default withSitecoreContext()(StarsRatingMobile);
