import React from 'react';
import { Link, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import RenderingStyling from '../../../lib/RenderingStyling';


const CallToActionButton = ({ fields,sitecoreContext,rendering }) => (
    sitecoreContext.pageEditing ?
    <div className="u-flex-row u-align-content-center u-margin-bottom-1 md:u-margin-right-1"  {...RenderingStyling(rendering.componentName)} >
        {
            <Link field={fields.link} data-track="true" data-track-value={fields.link.value.text} className={"c-cta-button " + fields.size?.fields.className?.value + " " + fields.type?.fields.className?.value }></Link>
        }
        
    </div>:
        fields.link?.value?.href !== '' &&
        <div className="u-flex-row u-align-content-center u-margin-bottom-1 md:u-margin-right-1">
        {
            fields.link.value.linktype === 'media' ?
            <div>
                <a data-track="true" data-track-value={fields.link.value.text}
                href={fields.link.value.href.replace('/sitecore/media-library','/-/media').replace('/sitecore/media library','/-/media') + '.ashx'} download className={"c-cta-button " + fields.size?.fields.className?.value + " " + fields.type?.fields.className?.value }>
                    {fields.link.value.text} 
                </a>
                <iframe id="my_iframe" style={{display : 'none'}}></iframe>
            </div>
            :
            <Link field={fields.link} data-track="true" data-track-value={fields.link.value.text} className={"c-cta-button " + fields.size?.fields.className?.value + " " + fields.type?.fields.className?.value }></Link>
        }
        
    </div>
    
);
 
export default  withSitecoreContext()(CallToActionButton);
