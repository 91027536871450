import * as React from 'react';
import { CookieConsent } from '@arm/ce-shared-components';
import './CookieConsent.css';
import config from '../../../temp/config';
import RenderingStyling from '../../../lib/RenderingStyling';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const ConfigCookieConsent = (props) => {
  let { fields, rendering, sitecoreContext } = props
  return <div role="navigation" aria-label="cookie consent" className="fixedPolicyBox" {...RenderingStyling(rendering.componentName)} >
    <CookieConsent
      sitecoreURI={config.REACT_APP_SITECOREURI}
      sitecoreEndpoint={config.REACT_APP_COOKIEENPOINT}
      sitecoreDatasource={fields.sitecoreDatasource.value}
      domain={config.REACT_APP_COOKIEDOMAIN}
      data={sitecoreContext?.ssr?.cookieData}
      disableCookie={true}
    />
  </div>
};

export default withSitecoreContext()(ConfigCookieConsent);
