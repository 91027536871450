import React from 'react';
import { withPlaceholder } from '@sitecore-jss/sitecore-jss-react';
import HomeCardsContainer from '../../SharedComponents/HomeCardsContainer';
import RenderingStyling from '../../../lib/RenderingStyling';

const HelpContainer = (props) => (
  <HomeCardsContainer {...RenderingStyling(props.rendering.componentName)} {...props} Cards={props.HelpCardsPlaceholder} />
)

export default withPlaceholder('HelpCardsPlaceholder')(HelpContainer);
