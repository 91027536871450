import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { withPlaceholder } from '@sitecore-jss/sitecore-jss-react';
import { AdsCtaLink } from '@arm/arm-design-system-react';
import RenderingStyling from '../../../lib/RenderingStyling';

const StartDevelopingCardsContainer = ({ fields, rendering, StartDevelopingCardsPlaceholder }) => (
<section id="start-developing" {...RenderingStyling(rendering.componentName)} class="dev-c-home-section--start-developing u-padding-top-2 u-padding-bottom-2 md:u-padding-top-2-1/2 md:u-padding-bottom-2-1/2 dark:u-bg-color-surface u-border-top-width-1 u-border-width-0 u-border-bottom-width-1 u-border-style-solid u-border-color-arm-web-safe-blue">
<div class="c-container">
  <div class="c-row">
    <div class="c-col">
    {fields.link!=null && fields.link.value.href!=""?
      <AdsCtaLink link={fields.link.value.href} srLabel={fields.heading.value} class="dev-c-home-cta-link u-margin-bottom-1 md:u-margin-bottom-1-1/2"><h2 class="u-color-inherit u-margin-0"><Text field={fields.heading} /></h2></AdsCtaLink>
    :
    <h2 class="u-color-inherit u-margin-bottom-1 md:u-margin-bottom-1-1/2 u-font-size-100% sm:u-font-size-200%"><Text field={fields.heading} /></h2>
    }
      </div>
  </div>
  <div class="c-row">
    <div class="c-col">
   
      <div class="u-display-grid u-gap-1 u-grid-columns-2 md:u-grid-columns-3 lg:u-grid-columns-6">

      {StartDevelopingCardsPlaceholder}
     
     </div>
   
    </div>
  </div>
</div>
</section>
);
export default withPlaceholder('StartDevelopingCardsPlaceholder')(StartDevelopingCardsContainer);
