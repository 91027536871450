import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import StarsRating from '../../SharedComponents/StarsRating';
import { withPlaceholder } from '@sitecore-jss/sitecore-jss-react';
import RenderingStyling from '../../../lib/RenderingStyling';

const SupportAndTrainingCardsContainer = ({ fields, rendering, SupportCardsPlaceholder, EventBannersPlaceholder, TrainingCardsPlaceholder }) => (

  <div {...RenderingStyling(rendering.componentName)} className="c-row md:is-collapsed u-padding-top-2-1/2 u-padding-bottom-2-1/2">
    <div className="c-col c-col-12">
      <div className="c-row dev-c-tab-heading c-horizontal-rule u-flex u-align-items-center lg:u-align-items-start u-justify-content-between u-margin-bottom-2">
        <div>
          <h2 className="u-margin-0"><Text field={fields.supportTitle} /></h2>
        </div>
                <StarsRating uid={rendering.uid} />
      </div>
      <div>
        <div className="lg:u-padding-left-1">
          <div className="u-display-grid u-gap-2 lg:u-grid-columns-3">
            {SupportCardsPlaceholder}         
          </div>
          <div class="u-margin-top-2">
            {EventBannersPlaceholder}
          </div>
        </div>
        <h2 id="training-courses" className="u-margin-top-2 u-margin-bottom-2"><Text field={fields.trainingTitle} /></h2>
        <div className="u-display-grid u-gap-2 lg:u-grid-columns-3 lg:u-padding-left-1">
          {TrainingCardsPlaceholder}
        </div>
      </div>
    </div>
  </div>
);
export default withPlaceholder(['SupportCardsPlaceholder', 'EventBannersPlaceholder', 'TrainingCardsPlaceholder'])(SupportAndTrainingCardsContainer);
