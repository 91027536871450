import React from 'react';
import { withPlaceholder, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { AdsHorizontalTabs } from '@arm/arm-design-system-react';
import './HorizontalTabsContainer.css';
import RenderingStyling from '../../../lib/RenderingStyling'

class HorizontalTabsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: ''
    }
  }

  getTabName = (event) => {
    let activeTabVal = 'slot-' + (event.detail.id).replace('slot-', '');
    this.setState({ data: activeTabVal });
    window.location.hash = '#' + (event.detail.id).replace('slot-', '');
  }

  componentDidMount() {
    let url = window.location.href;
    let activeTabVal = '';
    let urlParams = new URLSearchParams(window.location.search);
    let params = urlParams.get('params');    
    if (url.indexOf('#') > -1) {
      activeTabVal = 'slot-' + url.split('#')[1];
      this.setState({ data: activeTabVal });
    }
    else if(params != null) {
      let atParam = params.split(",")[0];
      activeTabVal = 'slot-' + atParam;
      this.setState({ data: activeTabVal });      
    }
    else {
        activeTabVal = this.props?.tabsPlaceholder[0]?.props?.fields?.heading?.value ? 'slot-' + this.props?.tabsPlaceholder[0]?.props?.fields?.heading?.value.replace(/ /g, '-') : 'slot-Technical-Specifications';
        this.setState({ data:  activeTabVal});
    }
  }

  componentDidUpdate(prevProps, prevState) {
    let url = window.location.href;
    if (url.indexOf('#') > -1) {
      let activeTabVal = 'slot-' + url.split('#')[1];
      if (prevState.data !== activeTabVal) {
        this.setState({ data: activeTabVal });
      }
    }
    
  }


  render() {
    const { tabsPlaceholder, sitecoreContext } = this.props;

    //child renderings configured to the tabsPlaceholder
    const renderings = (tabsPlaceholder || [])
      .filter((rendering) => rendering.props && rendering.props.fields)
      .map((tabRendering, index) => {
        return tabRendering;
      });

    //list of tabs data required to initialize ADS Horizontal tabs component
    const tabs = renderings.map((tabRendering, index) => {
      return { id: 'slot-' + tabRendering.props.fields.heading?.value.replace(/ /g, '-'), label: tabRendering.props.fields.heading?.value }
    });

    return (
      <React.Fragment>
        <section className="md:u-margin-left-2 md:u-margin-right-2"  {...RenderingStyling(this.props.rendering.componentName)}>
          {
             sitecoreContext.pageEditing ?
             <div id="horizontal-tab">
                 {renderings}
             </div>:
             this.state.data !== '' &&
                <AdsHorizontalTabs id="horizontal-tabs" breakpoint="md" data={tabs} activeTab={this.state.data} onTabSelect={(evt) => this.getTabName(evt)}>
                      {renderings}
                </AdsHorizontalTabs>
          }
        </section>
      </React.Fragment>
    );
  }


}
// This is a _higher order component_ that will wrap our component and inject placeholder
// data into it as a prop (in this case, props.tabsPlaceholder).
// this another option compared to using the <Placeholder> component;
// in this case, chosen because we primarily need access to the _data_
// of the placeholder.
const tabsComponentWithPlaceholderInjected = withPlaceholder({
  placeholder: 'jss-tabs',
  prop: 'tabsPlaceholder',
})(HorizontalTabsContainer);

// We need to know if experience editor is active, to disable the dynamic tab behavior for editing.
// Using the same technique as injecting the placeholder, we wrap the component again to inject the
// `sitecoreContext` prop.
const tabsWithPlaceholderAndSitecoreContext = withSitecoreContext()(
  tabsComponentWithPlaceholderInjected
);

export default tabsWithPlaceholderAndSitecoreContext;
