import React from 'react';
import { Footer as CEFooter } from '@arm/ce-shared-components';
import config from '../../../temp/config';
import RenderingStyling from '../../../lib/RenderingStyling';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const Footer = ({ fields, rendering, sitecoreContext }) => (
  <div  {...RenderingStyling(rendering.componentName)}>
    <CEFooter  
      sitecoreURI={config.REACT_APP_SITECOREURI}
      sitecoreEndpoint={config.REACT_APP_FOOTERSITECOREENDPOINT}
      sitecoreDatasource={fields.sitecoreDatasource.value}
      footerDomainUrl={config.REACT_APP_FOOTERDOMAINURL}
      data={sitecoreContext?.ssr?.footerData}
    />
  </div>
);
const FooterClient = () => (
  <div>
    <CEFooter  
      sitecoreURI={config.REACT_APP_SITECOREURI}
      sitecoreEndpoint={config.REACT_APP_FOOTERSITECOREENDPOINT}
      sitecoreDatasource={config.REACT_APP_FOOTERSITECOREDATASOURCE}
      footerDomainUrl={config.REACT_APP_FOOTERDOMAINURL}      
    />
  </div>
);

export default withSitecoreContext()(Footer);
export {FooterClient};
