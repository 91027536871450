import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { withPlaceholder } from '@sitecore-jss/sitecore-jss-react';
import RenderingStyling from '../../../lib/RenderingStyling';
import './FilterAndSearchCommunityPostsContainer.css'

const FilterAndSearchCommunityPostsContainer = ({ fields, rendering, CommunityBlogsPlaceHolder, CommunityForumsPlaceHolder}) => (
  <section  className="md:u-margin-left-2 md:u-margin-right-2" {...RenderingStyling(rendering.componentName)}>
    <div className="c-container is-full-width">
      <div className="c-row md:is-collapsed u-gap-1-1/2 u-flex-nowrap u-padding-top-2-1/2 u-padding-bottom-2-1/2">
        <div className="c-col c-col-12">
          <section className="community-section-FS">
            <h2 id="community-posts" className="u-margin-top-0 u-margin-bottom-1-1/2 u-margin-top-1-1/2"><Text field={fields?.title}/></h2>
            <div  className="u-display-grid u-gap-2 u-margin-bottom-1-1/2 xl:u-grid-columns-2">
              {CommunityBlogsPlaceHolder} 
            </div>      
            <div class="u-display-grid u-gap-1"> 
              {CommunityForumsPlaceHolder}
            </div>  
          </section>             
        </div>
      </div> 
    </div>    
  </section>    
);

export default withPlaceholder(['CommunityBlogsPlaceHolder','CommunityForumsPlaceHolder']) (FilterAndSearchCommunityPostsContainer);

