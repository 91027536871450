import React from 'react';
import {AzureB2CService, AuthConsumingService,OIDCSigninCallback, OIDCSignoutCallback} from "@arm/ce-shared-components/components/Authentication"; 
import { AdsLoader } from "@arm/arm-design-system-react";
import config from '../../temp/config';
import { UseGlobalState } from '../../context/index';
import { AZUREB2CSERVICE_INIT } from '../../constants/actionTypes';
import ThemeToggle  from '../../ThemeToggle';
export function getAppBaseUri() {
  return ((config.REACT_APP_PUBLIC_PATH) || "").replace("//", "/") + "/";
}


export function getSFaAuthConfig() {
  const baseUrl = config.REACT_APP_ORIGIN + getAppBaseUri();
  return {
    authority: config.REACT_APP_SFA_AUTHORITY,
    client_id: config.REACT_APP_SFA_CLIENT_ID,
    post_logout_redirect_uri: baseUrl + "sign-out",
    redirect_uri: baseUrl + config.REACT_APP_AUTH_SIGN_IN_URL,
    scope: config.REACT_APP_SFA_SCOPE,
    silent_redirect_uri: baseUrl + config.REACT_APP_AUTH_SILENT_SIGN_IN_URL,
    test: config.REACT_APP_ENV
  };
}


export const login = (props) => {
    AzureB2CService.init(
      AuthConsumingService,
      null,
      getSFaAuthConfig(),
      null,
      "/"
    ).then(() => {
      const params = new URLSearchParams(props.location.search);
      const returnUrl = params.get('returnUrl');
      AzureB2CService.login(
        false,
        new URL(`${config.REACT_APP_ORIGIN}${returnUrl}`));
    });
    return (
      <ThemeToggle>
        <div className="c-dh-content u-flex-center">
          <AdsLoader isLoading/>
        </div>
      </ThemeToggle>
    );
  };
  export const signin = () => {
    const [, dispatch] = UseGlobalState();
    const signinCallback = (user) => {
    dispatch({
      type: AZUREB2CSERVICE_INIT,
      data: { isAuth: true, user: user.profile },
    });
    return Promise.resolve(user);
  };
  return (
     <ThemeToggle>
      <OIDCSigninCallback onUserLoggedIn={signinCallback} />;
     </ThemeToggle>
    );
  };
  export const logout = () => {
    //Delete Coveo user token
    const coveoUserTokenName = 'coveoUserToken';
    const coveoUserToken = document.cookie.split('; ').find((row) => row.startsWith(coveoUserTokenName));
    if(coveoUserToken){
      const coveoUserTokenValue = coveoUserToken.split('=')[1];
      document.cookie = `${coveoUserTokenName}=${coveoUserTokenValue}; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/; secure`;
    }
    AzureB2CService.init(
      AuthConsumingService,
      null,
      getSFaAuthConfig(),
      "/"
    ).then(() => {
      AzureB2CService.logout();
    });
    return (
      <ThemeToggle>
        <div className="c-dh-content u-flex-center">
          <AdsLoader isLoading/>
        </div>
      </ThemeToggle>
    );
  };

  export const signOutCallback = () => {
    if(window != undefined)
    {
      window.location = config.REACT_APP_ORIGIN + "/";
    }
    return AzureB2CService.init(
      AuthConsumingService,
      null,
      getSFaAuthConfig(),
      getAppBaseUri(),
      true
    );
    
  };

  export const signOut = () => {
    return (
      <ThemeToggle>
        <OIDCSignoutCallback onUserLoggedOut={signOutCallback} />
      </ThemeToggle>
    );  
  };
  

export default login;