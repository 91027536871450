import React, {
  createContext,
  useContext,
  useReducer,
  Reducer,
  FunctionComponent,
} from 'react';
import {
  user,
  initialUserState,
} from './reducers';
export const StateContext = createContext(initialUserState);
export const StateProvider = ({
  // eslint-disable-next-line react/prop-types
  children,
}) => {
  const { Provider } = StateContext;
  const combineReducers = (slices) => (
    prevState,
    action
  ) =>
    Object.keys(slices).reduce(
      (nextState, nextProp) => ({
        ...nextState,
        [nextProp]: slices[nextProp](prevState[nextProp], action),
      }),
      prevState
    );
  const [state, dispatch] = React.useReducer(
  combineReducers({ user }),
  {
    user: initialUserState,
  }
);
  return <Provider value={[state, dispatch]}>{children}</Provider>;
};

export const UseGlobalState = () => useContext(StateContext);
