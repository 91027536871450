import React, { useEffect } from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import config from '../../../temp/config';

const ClientSideRedirect = ({ fields, sitecoreContext }) => {
    //handle media link
    let redirectLink = sitecoreContext?.route?.fields?.redirectUrl?.value;
    if (redirectLink?.linktype === 'media') {
        redirectLink.href = redirectLink.href
            .replace('/sitecore/media-library', '/-/media')
            .replace('/sitecore/media library', '/-/media');
    }

    //handle redirect url for both internal and external usecases
    let redirectUrl = new URL(
        redirectLink?.href ?? "", //redirect to Dev2.0 homepage if the redirect link isn't set
        typeof origin === 'undefined' ? config.REACT_APP_SITECOREORIGIN : origin
    );

    //bypass timer redirect for 'internal' links
    let isArmHostname = redirectUrl.hostname.endsWith('arm.com')
        || redirectUrl.hostname === 'localhost'
        || redirectUrl.origin === config.REACT_APP_SITECOREORIGIN;
    let timeout = isArmHostname ? 0 : (fields?.redirectTime?.value ?? 0);

    const [counter, setCounter] = React.useState(timeout);
    //redirect on timeout
    useEffect(() => {
        const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        if(counter == 0) window.location.replace(redirectUrl.href);   
        return () => clearTimeout(timer);
    }, [counter]);

    //format redirect text
    function formatJSXRedirectText(str, replace) {
        const parts = str?.replace('$time', counter).split('$link');
        const result = [];
        for (let i = 0; i < parts?.length; i++) {
            result.push(parts[i]);
            if (i < parts.length - 1) result.push(replace);
        } return result;
    }

    return (
        <section className="md:u-margin-left-2 md:u-margin-right-2 u-padding-top-2-1/2 u-padding-bottom-2-1/2">
          <div className="c-container is-full-width">
            <div className="c-row">
              <div className="c-col c-col-12">
                <div className="u-margin-top-0">{formatJSXRedirectText(fields?.redirectText?.value, <a href={redirectUrl.href} data-track="true" data-track-value={sitecoreContext?.route?.fields?.redirectUrl?.value?.text}>{sitecoreContext?.route?.fields?.redirectUrl?.value?.text}</a>)}</div>
              </div>
            </div>
          </div>
        </section>
    );
}

export default withSitecoreContext()(ClientSideRedirect);
