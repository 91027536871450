import React, {useEffect, useState} from 'react';
import { Text, Link, RichText, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import RenderingStyling from '../../../lib/RenderingStyling';
import Helmet from 'react-helmet';

const ProductProgramBanner = ({ fields,sitecoreContext,rendering }) => {
  const [styleString, setStyleString] = useState("")
  useEffect(() => {
    CheckVal();
  }, [styleString]);

  const [bgColorsForThemeChange, setbgColorsForThemeChange] = useState("")
  useEffect(() => {
    CheckVal();
  }, [bgColorsForThemeChange]);

  const darkBgColor = '#2d2f34';

  function CheckVal()
  {
    if(typeof window !=="undefined")
    {
      let isUseCaseContainerExists = document.getElementById('hdnIsSet')?.value;
      
      if(isUseCaseContainerExists != "1")
      {
        if(sitecoreContext.route.fields.backgroundColor && sitecoreContext.route.fields.backgroundColor.fields.value.value != '' && sitecoreContext.route.fields.backgroundColor.fields.value.value != 'Default')
        {
          let color =  sitecoreContext.route.fields.backgroundColor.fields.value.value;
          setbgColorsForThemeChange(color + '|' + darkBgColor);
          const themeTokenName = 'themeToken';            
          let themeToken;
          themeToken = document.cookie.split('; ').find((row) => row.startsWith(themeTokenName));
          if (themeToken && themeToken.split('=')[1] == 'dark') {                  
            color = darkBgColor; 
          }
          
          setStyleString(color);
        }
      }
    }
  }

  
  return(
    
  <div style={{ backgroundColor: `${styleString}`}} id='divProgramBanner'>
    
    {fields.Program != null &&
      <section {...RenderingStyling(rendering.componentName)} class="xl:u-margin-left-2 xl:u-margin-right-2 u-padding-top-2-1/2 u-padding-bottom-2-1/2">
        <input type='hidden' id='hdnBGColors' value={bgColorsForThemeChange}></input>
        <div class="c-container is-full-width">
          <div class="c-row xl:is-collapsed">
            <div class="c-col c-col-12">
              <h2 id="flexible-access" class="u-margin-top-0"><Text field={fields.title} /></h2>
            </div>
            <div class="c-col c-col-12 lg:c-col-9 u-padding-left-1">
              <h3><Text field={fields.Program.fields.heading} /></h3>
              <RichText field={fields.Program.fields.content} />
            </div>
            <div class="c-col c-col-12 lg:c-col-3 u-padding-left-1" id='relDescription'>
              <RichText
                field={fields.Program.fields.RelativeDescription}
                tag="section"
                editable={false}
                className="text-center"
                data-sample="other-attributes-pass-through"
              />
            </div>
          </div>

          <div class="u-padding-left-1 u-margin-top-2 u-flex u-flex-column md:u-flex-row u-align-content-center">

            {sitecoreContext.pageEditing?
            <div class="u-flex-row u-align-content-center u-margin-bottom-1 md:u-margin-bottom-0 md:u-margin-right-1">
              <Link field={fields.Button1_Url} data-track="true" data-track-value={fields.Button1_Url.value.text} className="c-cta-button is-medium is-primary"><Text field={fields.Button1_Text} /></Link>
            </div>: 
            fields.Button1_Url?.value?.href !== '' && fields.Button1_Text?.value !== '' &&
              <div class="u-flex-row u-align-content-center u-margin-bottom-1 md:u-margin-bottom-0 md:u-margin-right-1">
                <Link field={fields.Button1_Url} data-track="true" data-track-value={fields.Button1_Url.value.text} className="c-cta-button is-medium is-primary"><Text field={fields.Button1_Text} /></Link>
              </div>
                     
            
            }
            
            {sitecoreContext.pageEditing?
            <div class="u-flex-row u-align-content-center md:u-margin-right-1">
            <Link field={fields.Button2_Url} data-track="true" data-track-value={fields.Button2_Url.value.text} className="c-cta-button is-medium is-secondary"><Text field={fields.Button2_Text} /></Link>
          </div> :            
            fields.Button2_Url?.value?.href !== '' && fields.Button2_Text?.value !== '' &&
              <div class="u-flex-row u-align-content-center md:u-margin-right-1">
                <Link field={fields.Button2_Url} data-track="true" data-track-value={fields.Button2_Url.value.text} className="c-cta-button is-medium is-secondary"><Text field={fields.Button2_Text} /></Link>
              </div>
            }
          </div>
        </div>

      </section>
    }
  </div>
 )
};

export default withSitecoreContext()(ProductProgramBanner);
