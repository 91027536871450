import React from 'react';
import { Text,RichText } from '@sitecore-jss/sitecore-jss-react';
import {
  AdsCard,
  AdsCardContent
} from '@arm/arm-design-system-react';
import './HomeCard.css'

const HomeCard = ({fields}) => (
  <React.Fragment>
    <AdsCard className="dev-c-card dev-c-card--home dev-c-card--downloads" srLabel={fields.heading.value} link={fields.link.value.href} has-hover-effect="" orientation="vertical">
      <AdsCardContent slot="content" className="u-padding-1 u-padding-top-0">
        <div className="u-flex u-flex-column u-height-100%">
          <h3 className="dev-c-card__title u-font-size-150% u-font-weight-300"><Text field={fields.heading} /></h3>
          <div className="u-flex u-flex-column u-flex-1">
            <RichText className="u-flex-1" field={fields.content}></RichText>
          </div>
        </div>
      </AdsCardContent>
      </AdsCard>
  </React.Fragment>
);

export default HomeCard;
