import * as React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import {
  AdsCard,
  AdsCardContent
} from '@arm/arm-design-system-react';
import './UseCaseCard.css';
import RenderingStyling from '../../../lib/RenderingStyling';

const UseCaseCard = ({ fields, rendering }) => (
  <React.Fragment>
    <AdsCard sc-part-of="placeholder rendering" {...RenderingStyling(rendering.componentName)} srLabel={fields.heading.value} className="dev-c-card dev-c-card--use-case" link={fields.pageLink.value} has-hover-effect>
        <AdsCardContent slot="content" className="u-padding-1/2">
          <div className="u-margin-bottom-1/2 dark:u-color-arm-green"><Text field={fields.title} /></div>
          <h3 className="dev-c-card__title u-font-size-150% u-margin-top-0"><Text field={fields.heading} /></h3>
          <RichText field={fields.content} />
        </AdsCardContent>
      </AdsCard>
    
  </React.Fragment>
);

export default UseCaseCard;
