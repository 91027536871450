import React, { useEffect, useState } from 'react';
import { GlobalNav } from '@arm/ce-shared-components';
import config from '../../../temp/config';
import './GlobalNavigation.css';
import RenderingStyling from '../../../lib/RenderingStyling';
import {getAppBaseUri} from '../../Authentication';
import { UseGlobalState } from '../../../context';
import { initAzureB2CAction } from '../../../context/action/user';

const GlobalNavigation = ({ fields, rendering }) => {
    const [,dispatch] = UseGlobalState();
    useEffect(() => {
        initAzureB2CAction(dispatch);
    }, []);
    const  user = UseGlobalState();
    const [userData, setUserData] = useState(user[0].user);
    
    useEffect(() => {
        setUserData(user[0].user);
    }, user);
    return (
      <div className="dev-c-globalnav"   {...RenderingStyling(rendering.componentName)}  >

        <GlobalNav
          id="developerGlobalNav"    
          sitecoreUri={config.REACT_APP_SITECOREURI}      
          sitecoreEndpoint={config.REACT_APP_GLOBALNAVSITECOREENDPOINT}      
          sitecoreDatasource={fields.sitecoreDatasource.value}
          onThemeSwitch={(theme) => onThemeChangeEvent(theme)} 
          coveoOrganizationID= {config.REACT_APP_COVEOORGANIZATIONID}
          coveoGetTokenURI={config.REACT_APP_COVEOGETSEARCHTOKENURL}
          coveoSearchHub={config.REACT_APP_COVEOHOMEPAGESEARCHBOXSEARCHHUB}
          coveoQueryPipeline={config.REACT_APP_COVEOHOMEPAGESEARCHBOXQUERYPIPELINE}
          auth={{
            user: userData?.user,
            loginLink: config.REACT_APP_ORIGIN + config.REACT_APP_PATHNAME + `user-login${typeof window === "undefined"? "": "?returnUrl="+window.location.pathname}`,      
            logoutLink: config.REACT_APP_ORIGIN + config.REACT_APP_PATHNAME + 'user-logout',
            notificationsApi: config.REACT_APP_SITECORE_NOTIFICATIONS_API
          }}
          themeToggle={config.REACT_APP_THEME_TOGGLE === "true" ? true:false}
        />
      </div>
    );
}; 
const GlobalNavigationClient = () => {
    return (
      <div className="dev-c-globalnav">
        <GlobalNav
          id="developerGlobalNav"    
          themeToggle={config.REACT_APP_THEME_TOGGLE === "true" ? true:false}
          sitecoreUri={config.REACT_APP_SITECOREURI}      
          sitecoreEndpoint={config.REACT_APP_GLOBALNAVSITECOREENDPOINT}      
          sitecoreDatasource={config.REACT_APP_GLOBALNAVSITECOREDATASOURCE}
          coveoOrganizationID= {config.REACT_APP_COVEOORGANIZATIONID}
          coveoGetTokenURI={config.REACT_APP_COVEOGETSEARCHTOKENURL}
          coveoSearchHub={config.REACT_APP_COVEOHOMEPAGESEARCHBOXSEARCHHUB}
          coveoQueryPipeline={config.REACT_APP_COVEOHOMEPAGESEARCHBOXQUERYPIPELINE}
          onThemeSwitch={(theme) => console.log('theme switch callback. new theme:', theme)}      
          auth={{ 
          menuLinks: [
            {
                text: "Profile",
                link: config.REACT_APP_SITECORE_PROFILE_LINK

            },
            {
                text: "Settings",
                link: config.REACT_APP_SITECORE_SETTINGS_LINK
            },
            {
                text: "Notifications",
                link: config.REACT_APP_SITECORE_NOTIFICATIONS_LINK
            }
        ],      
          loginLink: `user-login?returnUrl=${window.location.pathname}`,      
          registerLink: "/register",      
          logoutLink: "/user-logout"}}
        />
      </div>
        
    );
}; 

function onThemeChangeEvent(currentTheme)
  {
    console.log('theme switch callback. new theme:', currentTheme)
    if(typeof window !=="undefined")
    {
      let hdnBGColors = document.getElementById('hdnBGColors')?.value;  
      if(hdnBGColors && hdnBGColors != '')
      {
        if (currentTheme == 'dark') {                  
          document.getElementById('divProgramBanner').style.backgroundColor = hdnBGColors.split('|')[1];      
        }
        else
        {
          document.getElementById('divProgramBanner').style.backgroundColor = hdnBGColors.split('|')[0];
        }
      } 
    }
  }

export default GlobalNavigation;
export {GlobalNavigationClient};
