import {AzureB2CService,AuthConsumingService, analytics}  from "@arm/ce-shared-components/components/Authentication";
import { getAppBaseUri, getSFaAuthConfig } from "../components/Authentication";

export const azureInit = () => {
  return AzureB2CService.init(AuthConsumingService, null, getSFaAuthConfig(), getAppBaseUri()).then(() => {
    return AzureB2CService.getToken(undefined).then(() => {
      return new Promise((res) => {
        res({
          user: AzureB2CService.getUser(),
          isAuth: AzureB2CService.isAuthenticated(),
          isLoginDone: true
        });
      });
    }).catch(() => {
      ;
    });
  }).catch((error) =>{
    console.log(error);
  });
}

