import React from 'react';
import { Text, RichText, Image } from '@sitecore-jss/sitecore-jss-react';
import {
  AdsCard,
  AdsCardContent
} from '@arm/arm-design-system-react';
import './SupportCard.css';
import RenderingStyling from '../../../lib/RenderingStyling';


const SupportCard = ({ fields,rendering }) => (
  <React.Fragment>
    <AdsCard {...RenderingStyling(rendering.componentName)} srLabel={fields.heading.value} className="dev-c-card dev-c-card--support" link={fields.pageLink.value} has-hover-effect>
      <AdsCardContent slot="content" className="u-padding-1 u-padding-top-0">
        <div className="c-row">
          <div className="c-col c-col-8 u-padding-0">
            <h3 className="dev-c-card__title u-font-size-150%"><Text field={fields.heading} /></h3>
            <RichText field={fields.content} />
          </div>
          <div className="c-col c-col-4 u-flex u-justify-content-center">
            <Image className="u-margin-auto" media={fields.image} />
          </div>
        </div>
      </AdsCardContent>
    </AdsCard>
  </React.Fragment>
);

export default SupportCard;
