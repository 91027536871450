import React from 'react';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import StarsRating from '../../SharedComponents/StarsRating';
import RenderingStyling from '../../../lib/RenderingStyling';

const SupportedDevicesContainer = ({ fields, rendering }) => (
    <div   {...RenderingStyling(rendering.componentName)} className="c-row md:is-collapsed u-padding-top-2-1/2 u-padding-bottom-2-1/2">
        <div class="c-col c-col-12">
            <div className="c-row dev-c-tab-heading c-horizontal-rule u-flex u-align-items-center lg:u-align-items-start u-justify-content-between">
                <div>
                    <h2 className="u-margin-0"><Text field={fields.heading} /></h2>
                </div>
                <StarsRating uid={ rendering.uid } />
            </div>
            <RichText field={fields.text}></RichText>
        </div>
    </div>
);

export default SupportedDevicesContainer;