import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { withPlaceholder,withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import RenderingStyling from '../../../lib/RenderingStyling';



const UseCaseCardsContainer = ({ fields, UseCaseCardsPlaceholder, rendering,sitecoreContext }) => {
  let bgColorstyle;
  let isUseCaseContainerExists = '';
  if(sitecoreContext.route.fields.backgroundColor && sitecoreContext.route.fields.backgroundColor.fields?.value?.value != '' && sitecoreContext.route.fields.backgroundColor.fields?.value?.value != 'Default')
  {
    bgColorstyle = sitecoreContext.route.fields.backgroundColor.fields.value.value;
    isUseCaseContainerExists = '1';
  }
  return(
  <section style={{ backgroundColor: bgColorstyle}} class="u-padding-top-2-1/2 u-padding-bottom-2-1/2 u-bg-color-arm-light-grey-alt-2 dark:u-bg-color-overlay-dark"   {...RenderingStyling(rendering.componentName)}>
    <input type='hidden' id='hdnIsSet' value={isUseCaseContainerExists}></input>
    <div class="md:u-margin-left-2 md:u-margin-right-2">
      <div class="c-container is-full-width">
        <div className="c-row xl:is-collapsed">
          <div className="c-col c-col-12">
            <h2 id="use-cases" className="u-margin-top-0"><Text field={fields.title} /></h2>
            <div className="c-row">
              <div className="c-col">
                <div className="u-display-grid u-gap-1 lg:u-grid-columns-3">
                  {UseCaseCardsPlaceholder}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

)};

export default withSitecoreContext()(withPlaceholder('UseCaseCardsPlaceholder')(UseCaseCardsContainer));
