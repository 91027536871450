import React from 'react';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import StarsRating from '../../SharedComponents/StarsRating';
import { withPlaceholder } from '@sitecore-jss/sitecore-jss-react';
import RenderingStyling from '../../../lib/RenderingStyling';

const ComponentsTabContainer = ({ fields, rendering, ComponentsCardsPlaceholder}) => (
    <div {...RenderingStyling(rendering.componentName)} className="c-row md:is-collapsed u-padding-top-2-1/2 u-padding-bottom-2-1/2">
        <div className="c-col c-col-12 ">
            <div className="c-row dev-c-tab-heading c-horizontal-rule u-flex u-align-items-center lg:u-align-items-start u-justify-content-between">
                <div>
                    <h2 className="u-margin-0"><Text field={fields.heading} /></h2>
                </div>
                <StarsRating uid={rendering.uid} />
            </div>
            <div className="u-padding-left-1 xl:u-padding-right-1">
                <RichText field={fields.text} />
            </div>
            <h3>
                <Text field={fields.cardsHeading}></Text>
            </h3>
            <div className="u-display-grid u-gap-1 lg:u-grid-columns-3">
                {ComponentsCardsPlaceholder}
            </div>
        </div>
    </div>
);

export default withPlaceholder('ComponentsCardsPlaceholder')(ComponentsTabContainer);