import React from 'react';
import { withPlaceholder, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import RenderingStyling from '../../../lib/RenderingStyling';
import { shouldHideSpotlightContent } from '../../../services/coveoQueryService';

const FilterAndSearchSpotlightContentVariantA = ({ SpotlightLinksPlaceholder, rendering, sitecoreContext }) => {

    return shouldHideSpotlightContent(sitecoreContext) ? "" :
        <div {...RenderingStyling(rendering.componentName)}>
            <ul className="dev-c-link-grid u-list-style-none u-text-indent-0 u-padding-0 u-margin-0 u-margin-bottom-1 lg:u-margin-top-0">
                {SpotlightLinksPlaceholder}
            </ul>
        </div>
}

const FilterAndSearchSpotlightContentVariantAWithPlaceholderInjected = withPlaceholder('SpotlightLinksPlaceholder')(FilterAndSearchSpotlightContentVariantA);

const FilterAndSearchSpotlightContentVariantAWithPlaceholderAndSitecoreContext = withSitecoreContext()(FilterAndSearchSpotlightContentVariantAWithPlaceholderInjected)

export default FilterAndSearchSpotlightContentVariantAWithPlaceholderAndSitecoreContext
