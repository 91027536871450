import React, { useState, useEffect } from 'react';
import './CommunityBlog.css';
import config from '../../../temp/config';
import RenderingStyling from '../../../lib/RenderingStyling';
import { CommunityBlogsCard } from '@arm/ce-shared-components/components/CoveoHeadless/CommunityComponents/Cards';
import { UseGlobalState } from '../../../context';

const CommunityBlog = ({fields, rendering})  => {
  const [{ user }] = UseGlobalState();
  const blogID = fields.OrFromBlogs?.map((blog) => "\""+blog.fields["Telligent Id"].value+"\"").toString();    
  const blogTagValues = fields.KeywordTags?.map((blog) => "\""+blog.displayName+"\"").toString();
  const blogText = fields.KeywordTagsText?.value.toString();
  const blogTagText = blogText== "" ? blogText : "\"" + blogText.split(',').join('\",\"') + "\"";
  const blogTag =blogTagValues == "" ? blogTagText: blogTagText == ""? blogTagValues : blogTagValues + ',' + blogTagText; 
  const numberofPosts = fields.NumberOfPosts?.value == "" ? 3 : fields.NumberOfPosts?.value;
  
  return (
    <React.Fragment {...RenderingStyling(rendering.componentName)}>
      <CommunityBlogsCard 
        CoveoOrganizationID= {`${config.REACT_APP_COVEOORGANIZATIONID}`}
        CoveoSearchHub= {`${config.REACT_APP_COVEOCOMMUNITYSEARCHHUB}`}
        CoveoQueryPipeline= {`${config.REACT_APP_COVEOCOMMUNITYQUERYPIPELINE}`}
        CoveoGetTokenURI= {`${config.REACT_APP_COVEOGETSEARCHTOKENURL}`}
        blogID= {blogID}
        blogTag= {blogTag}
        numberofPosts= {numberofPosts}
        user={{ token: user.user?.token, isLoggedIn: user.isAuth }}
      />
    </React.Fragment>
    
    
  );
}

export default CommunityBlog;

