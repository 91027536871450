import React from 'react';
import { Text} from '@sitecore-jss/sitecore-jss-react';
import RenderingStyling from '../../../lib/RenderingStyling';
const PromotionalBanner = ({fields, rendering}) => (
  <React.Fragment>
        <section {...RenderingStyling(rendering.componentName)} class="dev-c-hero u-flex u-flex-column u-justify-content-center" style={{minHeight: 443}} >
          <div class="dev-c-hero__background" style={{backgroundImage: `url("${fields.image.value.src}")`}} ></div>
          <div class="dev-c-hero__content u-padding-top-2 u-padding-bottom-2 u-bg-color-overlay-dark md:u-bg-color-transparent">
            <div class="c-container">
              <div class="c-row">
                <div class="c-col">
                  <h3 theme="dark" class="u-margin-0"><Text  field={fields.heading}></Text></h3>
                  <div class="u-margin-top-1 u-margin-bottom-1 md:u-margin-top-1-1/2 md:u-margin-bottom-1-1/2">
                  <p theme="dark" style={{maxWidth: 750}}>
                     <Text field={fields.description}></Text>
                    </p>
                  </div>
                  <a theme="dark" href={fields.link.value.href} data-track="true" data-track-value={fields.link.value.text} class="c-cta-button is-medium is-primary"> {fields.link.value.text} </a>
                  </div>
              </div>
            </div>
          </div>
        </section>
  </React.Fragment>
);
export default PromotionalBanner;