import React from 'react';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import RenderingStyling from '../../../lib/RenderingStyling';


const RichTextEditor = ({ fields, rendering }) => {

    return (
        <div {...RenderingStyling(rendering.componentName)} className='c-row'>
            <div className='c-col'>
                <h2 className="u-color-inherit u-margin-bottom-1 md:u-margin-bottom-1-1/2  u-font-size-100% sm:u-font-size-200%"><Text field={fields.heading}></Text></h2>
                <div className='c-col c-richtext-component'>
                    <RichText field={fields.RichTextField} tag="section" />
                </div>
            </div>
        </div>
    );
}

export default RichTextEditor;
