import React from 'react';
import { withPlaceholder } from '@sitecore-jss/sitecore-jss-react';
import RenderingStyling from '../../../lib/RenderingStyling';

const TopCardsSection = ({ rendering, TopCardsSectionPlaceholder }) => (
    <section {...RenderingStyling(rendering.componentName)} className="u-bg-color-arm-light-grey-alt dark:u-bg-color-base u-display-grid u-padding-top-1-1/2 u-padding-bottom-1-1/2 md:u-padding-top-2 md:u-padding-bottom-2 u-gap-1-1/2 md:u-gap-2">
        {TopCardsSectionPlaceholder}
    </section>
);

export default withPlaceholder('TopCardsSectionPlaceholder')(TopCardsSection);
