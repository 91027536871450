import React from 'react';
import { CoveoHeadless } from '@arm/ce-shared-components';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import { productHierarchyCoveoQuery } from '../../../services/coveoQueryService';
import config from '../../../temp/config';
import StarsRating from '../../SharedComponents/StarsRating';
import RenderingStyling from '../../../lib/RenderingStyling';
import { UseGlobalState } from '../../../context';


const ResourcesContainer = ({ rendering, sitecoreContext }) => {
  const coveoQuery = sitecoreContext?.route?.fields?.UXNSProducts ? productHierarchyCoveoQuery(sitecoreContext) : "";
  const [{ user }] = UseGlobalState();

  if (coveoQuery !== "") {
    const label = sitecoreContext.route?.fields?.UXNSProducts ? sitecoreContext.route.fields.UXNSProducts.reduce((acc, currentValue, index) => {
      acc = index === 0 ? currentValue.displayName : acc + `, ${currentValue.displayName}`
      return acc;
    }, "") : "";

    const resultViewType= () =>{
      let viewType="list";
      try {
        if(typeof window !=="undefined"){
          const userSelectedViewtype = localStorage?.getItem('viewtype');    
          if (userSelectedViewtype) {                  
            viewType = JSON.parse(userSelectedViewtype);                    
          }
          return viewType;
        }
      }
      catch(err) {
        console.log(err);
      } finally {
        return viewType;
      }
    }

    const resultItemType = {
      type: resultViewType(),
      variant: "landing-b",
      hideProductTags: true,
      hideBreadcrumb: true
    }

    return <div {...RenderingStyling(rendering.componentName)}>
        {user?.isLoginDone && 
          <CoveoHeadless CoveoOrganizationID={`${config.REACT_APP_COVEOORGANIZATIONID}`}
            CoveoGetTokenURI={`${config.REACT_APP_COVEOGETSEARCHTOKENURL}`}
            CoveoSearchHub={`${config.REACT_APP_COVEOPRODUCTPAGESEARCHHUB}`}
            CoveoQueryPipeline={`${config.REACT_APP_COVEOPRODUCTPAGEQUERYPIPELINE}`}
            tabId={`${config.REACT_APP_COVEORESOURCESTABID}`}
            scopedProductsLabel={label}
            user={{ token: user.user?.token, isLoggedIn: user.isAuth }}
            switchTagsLocation={true}        
            coveoQuery={coveoQuery}
            showCountsInFacet={false}
            resultItemType={resultItemType}
            facetsConfig={[
                {
                  title: "Confidential",
                  field: "confidentiality",
                  isHierarchial: false,
                  showCountsInFacet: false
                },
                {
                    title: "Resource Types",
                    field: "navigationhierarchiescontenttype",
                    showCountsInFacet: false,
                }, {
                    title: "Audience",
                    field: "navigationhierarchiesaudience",
                    showCountsInFacet: false
                }]}
            useUrlParams={false}
          >
            <StarsRating uid={ rendering.uid } />
          </CoveoHeadless>
        }
    </div>;
  } else {
    return "";
  }
};

export default withSitecoreContext()(ResourcesContainer);
