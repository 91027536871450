import React from 'react';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import {
    AdsCard,
    AdsCardContent,
    AdsCardImage,
    AdsTag
} from '@arm/arm-design-system-react';
import './FeatureCard.css'
import RenderingStyling from '../../../lib/RenderingStyling';

const FeatureCard = ({ fields, rendering }) => {
    return (
        <React.Fragment>
            <AdsCard  {...RenderingStyling(rendering.componentName)} srLabel={fields.heading.value} className="dev-c-card dev-c-feature-card dev-c-feature-card--main u-width-100%" link={fields.link?.value?.href} id={"ads-card-" + rendering.uid} orientation="vertical">
                <AdsCardImage src={fields.image?.value?.src} slot="image"></AdsCardImage>
                <AdsCardContent slot="content">
                    {fields.themes.length > 0 ? <AdsTag srLabel={fields.themes[0].displayName} href="#" className="dev-c-card-tag u-inline-block" onClick={(event) => { window.location.href=window.location.href+'&f-navigationhierarchiestopics='+ fields.themes[0].displayName;(event).preventDefault();}}  id={"ads-tag-" + fields.themes[0].id}>
                        <span className="fal fa-tag u-margin-right-1/2 rtl:u-margin-left-1/2 rtl:u-margin-right-0"></span>
                        {fields.themes[0].displayName}
                    </AdsTag> : "" }
                    <h3 className="dev-c-card__title u-grid-rows-1 u-margin-0"><Text field={fields.heading} /></h3>
                    <div id="subTitle"><RichText field={fields.subTitle} /></div>
                    <p className="u-margin-0"><Text field={fields.shortDescription} /></p>
                    {fields.themes.length > 1 ? <a class="dev-c-categories u-margin-0 u-text-bold" data-track="true" data-track-value={fields.themes[1].displayName}>{fields.themes[1].displayName}</a> : ""}
                </AdsCardContent>
            </AdsCard>
        </React.Fragment>
    );
};

export default FeatureCard;
