import React from 'react';
import { Text, Image } from '@sitecore-jss/sitecore-jss-react';
import {
  AdsCard,
  AdsCardContent
} from '@arm/arm-design-system-react';


import RenderingStyling from '../../../lib/RenderingStyling';

const StartDevelopingCard = ({fields, rendering}) => (
  <React.Fragment>
      <AdsCard {...RenderingStyling(rendering.componentName)} class="dev-c-card dev-c-card--home dev-c-card--start-developing" link={fields.link.value.href} srLabel={fields.heading.value} has-hover-effect>
          <AdsCardContent slot="content">
            <div class="u-flex u-flex-column u-align-items-center">
              <picture class="dev-c-card--start-developing__icon">
                <Image class="default:u-display-block dark:u-display-none" media={fields.lightImage} />
                <Image class="default:u-display-none dark:u-display-block" media={fields.darkImage} />
              </picture>
              <h3 class="dev-c-card__title u-font-size-100% u-font-weight-400 u-text-center"><Text field={fields.heading} /></h3>
            </div>
          </AdsCardContent>
        </AdsCard>
  </React.Fragment>
);

export default StartDevelopingCard;
