import React from 'react';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { Image } from '@sitecore-jss/sitecore-jss-react';
import {
  AdsCard,
  AdsCardContent
} from '@arm/arm-design-system-react';
import RenderingStyling from '../../../lib/RenderingStyling';

import './ToolsAndSoftwareCard.css';

const ToolsAndSoftwareCard = ({ fields,rendering }) => (

  <React.Fragment>
    <AdsCard  {...RenderingStyling(rendering.componentName)} srLabel={fields.heading.value}  className='dev-c-card dev-c-card--tools-software' link={fields.link.value.href} has-hover-effect>
      <AdsCardContent slot="content" className='u-padding-1 u-padding-top-0'>
        <div className="dev-c-card__title c-heading-3 u-font-size-150%"><Text field={fields.heading} /></div>
        <div className="c-row">
          <div className="c-col c-col-9 u-padding-0">
            <RichText field={fields.content}></RichText>
          </div>
          <div className='c-col c-col-3 u-justify-content-center'>
            <Image media={fields.image} className="u-margin-auto" />
          </div>
        </div>
      </AdsCardContent>
    </AdsCard>
  </React.Fragment>
);


export default ToolsAndSoftwareCard;

