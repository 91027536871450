import React from 'react';
import config from '../temp/config';
import "./ErrorComponent.css"


const links = [
  { label: "Arm Developer home",  path: `${config.REACT_APP_ERROR_PAGE_DEVELOPER_HOME_LINK}`},
  { label: "Browse our documentation", path: `${config.REACT_APP_ERROR_PAGE_DOCUMENTATION_LINK}` },
  { label: "Read our blogs",  path: `${config.REACT_APP_ERROR_PAGE_BLOGS_LINK}` }
];

const errorMessages = {
  "/500":
  {
    errorCode: "500",
    title: "Sorry, this site is not working properly",
    message: ["We are aware of the problem and are working to get it fixed as soon as possible."],
    linkMsg: "In the meantime, here are some links that may help:",
    links
  
},
"/404":
 {
  errorCode: "404",
  title: "We could not find this page",
  message: ["The link that sent you here may be incorrect or the page has moved"],
  linkMsg: "Here are some links that may help:",
  links
},
};


const renderLinks =(errorMsg)  => {
  return (
    <React.Fragment>
      <p>{ errorMsg.linkMsg }</p>
      <ul>
        { errorMsg.links.map((link, index) =>
          <li key={index}>
            <a className="c-anchor" href={link.path} data-track="true" data-track-value={link.label}>{link.label}</a>
          </li>
        )}
      </ul>
    </React.Fragment>
  );
}

const ErrorComponent = (props ) => {
  const pathname = props.route;
  const errorMsg = errorMessages[pathname] || errorMessages["/404"];
  return (
    <div className="c-dev-wrapper">
      <div className="c-dev-content-error-wrapper u-margin-bottom-2">
        <h2 className="c-dev-errorpage">{errorMsg.title}</h2>
        <div className="c-dev-errorpage-message">
          <p>{errorMsg.message}</p>
          {errorMsg.links.length > 0 && renderLinks(errorMsg)}
        </div>
        <div className={"c-dev-errorpage-image-" + errorMsg.errorCode} />
      </div>
    </div>
  );
};

export default ErrorComponent;
