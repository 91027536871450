import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import {
    AdsCard,
    AdsCardContent
} from '@arm/arm-design-system-react';
import { withPlaceholder } from '@sitecore-jss/sitecore-jss-react';
import RenderingStyling from '../../../lib/RenderingStyling';

const EventListCard = ({ fields, rendering, EventLinksPlaceholder}) => (
    <React.Fragment>
        <AdsCard  {...RenderingStyling(rendering.componentName)} className="dev-c-event-list-card u-bg-color-arm-light-grey" id={"ads-card-" + rendering.uid} orientation="vertical">
            <AdsCardContent slot="content" className="u-display-grid u-gap-1 u-padding-1">
                <div className="u-flex u-flex-column u-height-100">
                    <h3 className="u-margin-top-0 u-margin-bottom-1 u-color-arm-black dark:u-color-white"><Text field={fields.heading} /></h3>
                    <div className="u-display-grid u-gap-1/2 dev-c-event-list__events">
                        {EventLinksPlaceholder}
                    </div>
                </div>
            </AdsCardContent>
        </AdsCard>
    </React.Fragment>
);

export default withPlaceholder('EventLinksPlaceholder')(EventListCard);
