import React from "react";
import { KeyResources as CEKeyResources } from "@arm/ce-shared-components";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { productHierarchyCoveoQuery } from "../../../services/coveoQueryService";
import config from "../../../temp/config";
import "./KeyResources.css";
import RenderingStyling from "../../../lib/RenderingStyling";
import { UseGlobalState } from "../../../context";

const KeyResources = ({ fields, sitecoreContext, rendering }) => {
  const coveoQuery = productHierarchyCoveoQuery(sitecoreContext);

  const selected_resource_Data = fields?.select_resource;
  const resource = selected_resource_Data?.map((resource) => {
    return {
      text: resource?.fields?.link?.value?.text,
      url: resource?.fields?.link?.value?.url,
      target: resource?.fields?.link?.value?.target,
    };
  });

  const [{ user }] = UseGlobalState();

  return (
    <div
      {...RenderingStyling(rendering.componentName)}
      className="dev-c-keyResources"
    >
      <CEKeyResources
        CoveoOrganizationID={`${config.REACT_APP_COVEOORGANIZATIONID}`}
        CoveoGetTokenURI={`${config.REACT_APP_COVEOGETSEARCHTOKENURL}`}
        CoveoSearchHub={`${config.REACT_APP_COVEOPRODUCTPAGESEARCHHUB}`}
        CoveoQueryPipeline={`${config.REACT_APP_COVEOPRODUCTPAGEQUERYPIPELINE}`}
        CoveoRecommendedSearchHub={`${config.REACT_APP_COVEOPRODUCTPAGERECOMMENDATIONSEARCHHUB}`}
        CoveoRecommendedQueryPipeline={`${config.REACT_APP_COVEOPRODUCTPAGERECOMMENDATIONQUERYPIPELINE}`}
        searchEndPoint={`${config.REACT_APP_COVEOCACHEDENDPOINT}`}
        user={{ token: user.user?.token, isLoggedIn: user.isAuth }}
        tabId={`${config.REACT_APP_COVEOKEYRESOURCESTABID}`}
        coveoQuery={coveoQuery}
        viewMoreLinkUrl={"#Resources"}
        sitecoreResources={resource}
      />
    </div>
  );
};

export default withSitecoreContext()(KeyResources);
