import React from 'react';
import { Text, RichText, Image } from '@sitecore-jss/sitecore-jss-react';
import {
  AdsCard,
  AdsCardContent
} from '@arm/arm-design-system-react';
import './TrainingCard.css';
import RenderingStyling from '../../../lib/RenderingStyling';

const TrainingCard = ({ fields,rendering }) => (
  <React.Fragment>
    <AdsCard   {...RenderingStyling(rendering.componentName)} srLabel={fields.heading.value}  className="dev-c-card dev-c-card--training" link={fields.pageLink.value} has-hover-effect>
      <AdsCardContent slot="content" className="u-padding-1 u-padding-top-0">
        <div className="c-row">
          <Image className="u-margin-auto" media={fields.image} />
          <div className="c-col u-padding-left-1/2">
            <span className="u-color-arm-dark-grey"><Text field={fields.title} /></span>
            <span className="u-display-block u-font-size-75%"><Text field={fields.subTitle} /></span>
          </div>
        </div>
        <div className="c-row">
          <h3 className="dev-c-card__title u-font-size-150%"><Text field={fields.heading} /></h3>
          <RichText field={fields.content} />
        </div>
      </AdsCardContent>
    </AdsCard>
  </React.Fragment>
);

export default TrainingCard;
