import React from 'react';
import { Text, RichText, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import './videocontent.css';

const VideoContent = ({sitecoreContext}) => {
    return(
        <section className="md:u-margin-left-2 md:u-margin-right-2 u-padding-bottom-2-1/2">
          <div className="c-container is-full-width">
            <div className="c-row">
              <div className="c-col c-col-12">
                <div className="u-margin-top-0"><h3>{sitecoreContext.route.fields?.title?.value}</h3>
                    {sitecoreContext.route.fields?.VideoLink?.value.linktype === 'external'?
                      <object data={sitecoreContext.route.fields?.VideoLink?.value.href} controls = {true} className="videoContainer"></object>
                      :
                      <video src={sitecoreContext.route.fields?.VideoLink?.value.href.replace("/sitecore/media-library","/-/media").replace("/sitecore/media library","/-/media") + '.ashx'} controls = {true} className="videoComponentInternal"></video>
                    }
                    
                    <h2 class="u-margin-top-0" id="specification"><Text field={sitecoreContext.route.fields?.heading}></Text></h2>
                    <div class="u-padding-left-1 xl:u-padding-right-1">
                        <RichText field={sitecoreContext.route.fields?.content} />
                    </div>
                </div>
              </div>
            </div>
          </div>
        </section>
    );
};

export default withSitecoreContext()(VideoContent);
