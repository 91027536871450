import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { withPlaceholder } from '@sitecore-jss/sitecore-jss-react';
import RenderingStyling from '../../../lib/RenderingStyling';

const CommunityPostsContainer = ({ fields, CommunityBlogsPlaceHolder, CommunityForumsPlaceHolder, rendering}) => (
  <section className="u-padding-top-2-1/2 u-padding-bottom-2-1/2" {...RenderingStyling(rendering.componentName)} >
      <div className="md:u-margin-left-2 md:u-margin-right-2">
        <div className="c-container is-full-width">
          <div class="c-row md:is-collapsed">
              <div class="c-col c-col-12">
                <h2 id="popular-posts" className="u-margin-top-0 u-margin-bottom-2"><Text field={fields?.title} /></h2>
                <div class="lg:u-padding-left-1 u-display-grid u-gap-2 u-margin-bottom-2 xl:u-grid-auto-flow-column">
                  {CommunityBlogsPlaceHolder}
                </div>
                  {CommunityForumsPlaceHolder}
              </div>
          </div>
        </div>
      </div>
    </section>
);

export default withPlaceholder(['CommunityBlogsPlaceHolder','CommunityForumsPlaceHolder'])(CommunityPostsContainer);
