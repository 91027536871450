import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { withPlaceholder } from '@sitecore-jss/sitecore-jss-react';
import StarsRating from '../../SharedComponents/StarsRating';
import './WorksWellWithCardsContainer.css';
import RenderingStyling from '../../../lib/RenderingStyling';

const WorksWellWithCardsContainer = ({ fields, rendering, WorksWellWithCardsPlaceholder }) => (

  <div   {...RenderingStyling(rendering.componentName)}  className="c-row md:is-collapsed u-gap-1-1/2 u-flex-nowrap u-padding-top-2-1/2 u-padding-bottom-2-1/2">
    <div className="c-col c-col-12 ">
      <div className="c-row dev-c-tab-heading dev-c-tab-filter-heading c-horizontal-rule u-display-grid u-gap-1 lg:u-gap-0 lg:u-flex u-align-items-center lg:u-align-items-start u-margin-bottom-2 u-position-relative lg:u-justify-content-between">
        <h2 className="u-margin-0"><Text field={fields.title}></Text></h2>
        <StarsRating uid={ rendering.uid } />
      </div>
      <div className="u-display-grid u-gap-1 md:u-grid-columns-2 xl:u-grid-columns-3 xxl:u-grid-columns-4">
        {WorksWellWithCardsPlaceholder}
      </div>
    </div>
  </div>
);


export default withPlaceholder('WorksWellWithCardsPlaceholder')(WorksWellWithCardsContainer);

