import React from 'react';
import { Link, DateField } from '@sitecore-jss/sitecore-jss-react';
import RenderingStyling from '../../../lib/RenderingStyling';

const EventLink = ({ fields, rendering }) => {
    return (
        <div class="dev-c-event u-display-grid" id={rendering.uid}   {...RenderingStyling(rendering.componentName)} >
            <div className="dev-c-event__title">
                <h2><Link field={fields.link} data-track="true" data-track-value={fields.link.value.text}/></h2>
            </div>
            <DateField field={fields.date} render={(date) => <time datetime={date.toLocaleString()}>{date.toDateString()}</time>} />
            {fields.type?.fields?.value?.value ? <p class="dev-c-event-category">{fields.type?.fields?.value?.value}</p> : ""}
        </div>
    );
};

export default EventLink;
