import React from 'react';
import { withSitecoreContext, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import RenderingStyling from '../../../lib/RenderingStyling'

const HorizontalTab = (props) => {
    return (
        <React.Fragment>
            <div slot="content" data-id={'slot-' + props.rendering.fields?.heading?.value.replace(/ /g, '-')} className="c-container is-full-width" id={'tabs:horizontal-tabs:panel:slot-' + props.rendering.fields?.heading?.value.replace(/ /g, '-')} role="tabpanel" {...RenderingStyling(props.rendering.componentName)}>
                <Placeholder
                    rendering={props.rendering}
                    name="tab-content" />
            </div>
        </React.Fragment>
    );
};

export default withSitecoreContext()(HorizontalTab);
