import * as React from "react";
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

function JsonLdHelper(fields) {

  this.jsonLdScriptBody =
  {
    "@context": "http://schema.org",
    "@type": "TechArticle",
    "name": fields.pageTitle?.value,
    "description": fields.pageDescription?.value,
  }

  this.extreactedFieldData = "";

  this.createJsonLdField = (fieldData, fieldkey) => {

    if (fieldData) {

      if (Array.isArray(fieldData)) {
        this.extreactedFieldData = fieldData.map((data) => {
          return data.fields.HierarchyPaths.value;
        });
      }

      if (fieldData.value?.fields) {
        this.extreactedFieldData = fieldData.value.fields.HierarchyPaths.value;
      }

    }

    return this.addFieldToJsonLd(fieldkey)
  }


  this.addFieldToJsonLd = (fieldkey) => {

    if (this.extreactedFieldData) {
      this.jsonLdScriptBody[fieldkey] = this.extreactedFieldData;
    }

    return this
  }

  this.getLd = () => {
    return this.jsonLdScriptBody;
  }
}


const JsonLdData = ({ fields, rendering, sitecoreContext }) => {

  const helper = new JsonLdHelper(fields);

  let jsonLdScriptBody = helper
    .createJsonLdField(fields.UXNSAudience,"audience")
    .createJsonLdField(fields.UXNSCategories, "categories")
    .createJsonLdField(fields.UXNSContentType, "contentType")
    .getLd()

  return (
    <script id="dynamicJSONLD" type="application/ld+json">
      {JSON.stringify(jsonLdScriptBody)}
    </script>
  );
}

export default withSitecoreContext()(JsonLdData);
