import React from 'react';
import {
    AdsCard,
    AdsCardContent
} from '@arm/arm-design-system-react';
import { Text, Link, RichText } from '@sitecore-jss/sitecore-jss-react';
import RenderingStyling from '../../../lib/RenderingStyling';
import './AdditionalResourceCard.css';

const AdditionalResourcesCard = ({fields, rendering}) => (
    <React.Fragment>
        <AdsCard {...RenderingStyling(rendering.componentName)} className="dev-c-card--additional-resources u-display-block u-margin-top-1" id="ads-card-gej2htvd2ab" orientation="vertical">
            <AdsCardContent slot="content">
                <div className="u-padding-1 u-padding-top-0">
                    <h3><Text field={fields.heading} /></h3>
                    <ul className="dev-c-key-additional-resources__list u-padding-left-1/2">
                        {fields.links &&
                            fields.links.map((listItem, index) => (
                                <li key={`listItem-${index}`}>
                                    <a href={listItem.url} data-track="true" data-track-value={listItem.fields.pageTitle.value}><Text field={listItem.fields.pageTitle} /></a>
                                </li>
                            ))}
                    </ul>
                    <div className='dev-c-key-additional-resources__list u-padding-left-1/2 u-margin-bottom-1 div-aditional-resouce-external-link'>
                        <RichText field={fields.otherLinks}></RichText>
                    </div>
                    <span className="dev-c-cta-link c-cta-link">
                        <Link field={fields.viewAll} className="c-cta-link__anchor u-text-bold" data-track="true" data-track-value={fields.viewAll.value.text}></Link>
                        <span className="c-cta-link__icon" aria-hidden="true">
                            <span className="fal fa-chevron-right"></span>
                        </span>
                    </span>
                </div>
            </AdsCardContent>
        </AdsCard>
    </React.Fragment>
);

export default AdditionalResourcesCard;
