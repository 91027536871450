import React from 'react';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { GlobalSearchBox } from '@arm/ce-shared-components';
import config from '../../../temp/config';
import RenderingStyling from '../../../lib/RenderingStyling';
import './homepageMasthead.css';
import { UseGlobalState } from '../../../context';

const HomepageMasthead = (props) => {
  const [{ user }] = UseGlobalState();
    return (
        <header id="homeHeader" {...RenderingStyling(props.rendering.componentName)} className="dev-c-home-header u-flex u-flex-column u-justify-content-center md:u-justify-content-start u-border-width-0 u-border-bottom-width-1 u-border-style-solid u-border-color-arm-web-safe-blue">
            <div className="dev-c-home-header__container c-container">
                <div className="c-row u-justify-content-center">
                    <div className="c-col md:c-col-10">
                        <h1 className="u-color-arm-web-safe-blue dark:u-color-arm-light-blue u-margin-0 u-text-center">
                            <RichText field={props.fields.heading}/>
                        </h1>
                        <div className="u-margin-top-1-1/2 u-margin-bottom-1-1/2 md:u-margin-top-2 md:u-margin-bottom-2" style={{ minHeight: "66px" }}>
                            <GlobalSearchBox coveoOrganizationID={`${config.REACT_APP_COVEOORGANIZATIONID}`}
                                coveoGetTokenURI={`${config.REACT_APP_COVEOGETSEARCHTOKENURL}`}
                                coveoQueryPipeline={`${config.REACT_APP_COVEOHOMEPAGESEARCHBOXQUERYPIPELINE}`}
                                coveoSearchHub={`${config.REACT_APP_COVEOHOMEPAGESEARCHBOXSEARCHHUB}`}
                                user={{ token: user.user?.token, isLoggedIn: user.isAuth }}
                                searchPage={`${config.REACT_APP_COVEOSEARCHPAGE}`}
                                placeholderSearchBox={props.fields.placeholderSearchBox?.value}
                            />
                        </div>
                        <div>
                            <div className="u-text-center u-margin-bottom-1-1/2 md:u-margin-bottom-1"><Text field={props.fields.text}/></div>
                            <div className="u-flex u-flex-wrap u-gap-1 u-justify-content-center">
                               {props.fields.themes?.map((theme) =>
                                  <a className="c-tag dev-c-card-tag" data-track="true" data-track-value={theme.fields.label?.value} href={theme.fields.link?.value?.url}>{theme.fields.label?.value}</a>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default HomepageMasthead;
