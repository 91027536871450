import React from 'react';
import { withPlaceholder, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import RenderingStyling from '../../../lib/RenderingStyling';
import { shouldHideSpotlightContent } from '../../../services/coveoQueryService';

const FilterAndSearchSpotlightContentVariantB = ({ rendering, sitecoreContext, FeaturedCardsPlaceholder }) => {
    return shouldHideSpotlightContent(sitecoreContext) ? "" : 
      <div {...RenderingStyling(rendering.componentName)}>
        <section className="u-display-grid u-gap-1 u-grid-columns-1 xl:u-grid-columns-3 u-margin-bottom-1-1/2" >
            {FeaturedCardsPlaceholder}
        </section>   
      </div>
}


const FilterAndSearchSpotlightContentVariantBWithPlaceholderInjected = withPlaceholder('FeaturedCardsPlaceholder')(FilterAndSearchSpotlightContentVariantB);

const FilterAndSearchSpotlightContentVariantBWithPlaceholderAndSitecoreContext = withSitecoreContext()(FilterAndSearchSpotlightContentVariantBWithPlaceholderInjected)

export default FilterAndSearchSpotlightContentVariantBWithPlaceholderAndSitecoreContext