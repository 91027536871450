import React from 'react';
import Helmet from 'react-helmet';
import config from '../temp/config';

const themeToggleValue = (`${config.REACT_APP_THEME_TOGGLE}` === "true" ? true:false);
const ThemeToggle = (props) => {
  return (
    <>
    <Helmet>    
        {
          themeToggleValue && <script>
            {`            
              const themeTokenName = 'themeToken';
              let themeToken;
              if (typeof window !=="undefined"){
                  themeToken = document.cookie.split('; ').find((row) => row.startsWith(themeTokenName));
                  if (themeToken) {                 
                    document.querySelector('html').setAttribute('theme', themeToken.split('=')[1]);                
                  }else{
                    document.querySelector('html').setAttribute('theme', 'dark');
                  }
              }
            `}
          </script>
        }
      </Helmet>
      {props.children}
    </>
  );
};
export default ThemeToggle;
  