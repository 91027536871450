import React from 'react';
import { Text, RichText, Image,withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import {
  AdsCard,
  AdsCardContent
} from '@arm/arm-design-system-react';
import './EventBanner.css';
import RenderingStyling from '../../../lib/RenderingStyling';

const EventBanner = ({ fields, sitecoreContext, rendering }) => (
  <React.Fragment>    
    <AdsCard {...RenderingStyling(rendering.componentName)} srLabel={fields.eventHeading.value} className="dev-c-devsummit-card" link={fields.eventLink.value}>
      <AdsCardContent slot="content">
        <div className="dev-c-devsummit-card__content c-row u-padding-1">
          <div className="dev-c-devsummit-card__heading">
            <span className="u-font-size-90% dark:u-color-arm-dark-grey"><Text field={fields.eventTitle} /></span>
            <div className="u-font-size-125%"><Text field={fields.eventHeading} /></div>
            <div className="u-font-size-110% u-margin-bottom-1"><Text field={fields.eventDetails} /></div>
          </div>
          <div className="dev-c-devsummit-card__image u-flex u-justify-content-center md:u-padding-left-2 u-width-100% md:u-width-auto">
            <Image className="u-margin-auto u-width-100%" media={fields.eventImage} />
          </div>
          <div className="dev-c-devsummit-card__paragraph u-flex u-flex-column lg:u-flex-row u-align-items-center md:u-align-items-start lg:u-flex-wrap u-padding-top-1 lg:u-padding-top-0 u-margin-bottom-1">
            <div class="u-margin-bottom-1">
              <RichText field={fields.eventContent} />
            </div>
            <div>
            {sitecoreContext.pageEditing?
              <a href={fields.ctaLink.value} className="c-cta-button is-primary is-medium" data-track="true" data-track-value={fields.ctaName.value}><Text field={fields.ctaName} /></a>:
              fields.ctaLink?.value !== '' && fields.ctaName !== '' &&
              <a href={fields.ctaLink.value} className="c-cta-button is-primary is-medium" data-track="true" data-track-value={fields.ctaName.value}><Text field={fields.ctaName} /></a>
            }
            </div>
          </div>
        </div>
      </AdsCardContent>
    </AdsCard>
  </React.Fragment>
);

export default withSitecoreContext()(EventBanner);
