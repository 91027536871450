import React from 'react';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import {
  AdsCard,
  AdsCardContent
} from '@arm/arm-design-system-react';
import './WorksWellWithCard.css';
import RenderingStyling from '../../../lib/RenderingStyling';
const WorksWellWithCard = ({ fields,rendering }) => (

  <React.Fragment>
    <AdsCard className="dev-c-card dev-c-filter-card" link={fields.link.value.href} srLabel={fields.heading.value} {...RenderingStyling(rendering.componentName)} >
      <AdsCardContent slot="content" >
        <div className="dev-c-filter-card__content u-padding-left-1">
          <span className="dev-c-filter-card__subtitle u-color-arm-dark-grey"><Text field={fields.heading}></Text></span>
          <div className="dev-c-card__title dev-c-filter-card__title"><Text field={fields.subHeading}></Text> </div>
          <RichText field={fields.content}></RichText>
        </div>
      </AdsCardContent>
    </AdsCard>
  </React.Fragment>
);


export default WorksWellWithCard;

