import React from 'react';
import Helmet from 'react-helmet';
import ErrorComponent from './ErrorComponent';
import {GlobalNavigationClient} from './components/SharedComponents/GlobalNavigation';
import {FooterClient} from './components/SharedComponents/Footer';



// Renders a route-not-found message when no route is available from Sitecore
// The JSS equivalent of a 404 Not Found page.

// This is invoked from RouteHandler when Sitecore returns no valid route data.
// The NotFound component receives the Layout Service Context data, but no route data.
// This can be used to power parts of your site, such as navigation, from LS context additions
// without losing the ability to render them on your 404 pages :)

const ErrorPage = (props) => (
  <div>
    <Helmet>
      <title>Page not found</title> 
    </Helmet>

    <div role="navigation" class="dev-c-skip-navigation u-margin-0 u-padding-0 u-position-relative" aria-label="skip navigation">
      <a href="#header" data-track="true" data-track-value="Skip Navigation (Press Enter)">Skip Navigation (Press Enter)</a>
      <a href="#content" data-track="true" data-track-value="Skip to Content (Press Enter)">Skip to Content (Press Enter)</a>
    </div>
    <div role="navigation" className="dev-c-navigation-wrapper global-nav-wrapper u-bg-color-overlay-dark u-layer-16 u-position-sticky">
      <GlobalNavigationClient/>  
    </div>
    <ErrorComponent route={props.route}/>    
    <div className="footer-wrapper">      
      <FooterClient />
    </div>      
  </div>
);

export default ErrorPage;
