

export const productHierarchyCoveoQuery = (sitecoreContext) => {
  return sitecoreContext?.route?.fields?.UXNSProducts ? sitecoreContext.route.fields.UXNSProducts.reduce((acc, currentValue, index) => {
    const hierarchyPathsArray = currentValue.fields.HierarchyPaths.value.split(";");
    const hierarchyPathsValue = hierarchyPathsArray.pop();
    acc = index === 0 ? `@navigationhierarchiesproducts=="${hierarchyPathsValue}"` : acc + ` OR @navigationhierarchiesproducts=="${hierarchyPathsValue}"`;
    return acc;
  }, "") : "";
}

export const shouldHideSpotlightContent = (sitecoreContext) => {
    if (sitecoreContext.pageEditing || typeof window === "undefined") return false;
    let queryParams = window.location.hash?.substring(1)?.split('&');
    return queryParams?.find(x => x.startsWith('q='))?.length > 0
        || queryParams.find(x => x.startsWith('f['))?.length > 0
        || queryParams.find(x => x.startsWith('cf['))?.length > 0
        || queryParams.find(x => x.startsWith('f-'))?.length > 0
        || queryParams.find(x => x.startsWith('cf-'))?.length > 0;
}